import React from "react";
import { Link, browserHistory } from "react-router";
import DatePicker from "react-datepicker";
import moment from "moment";
import ConfirmationModal from "../../shared/ConfirmationModal";
import FadeOutErrorMessage from "../../shared/FadeOutErrorMessage";
import ProgramBreadcrumbsHeader from "../../common/ProgramBreadcrumbsHeader";
import Loader from "../../common/Loader";
import NumberFormat from "react-number-format";
import Select from "react-select";
import Toggle from "../../shared/Toggle";

import EduApi from "../../../services/resources/EduApi";
import SystemApi from "../../../services/resources/SystemApi";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import { Unauthorized } from "../../../lib/coc-common-components";
import { notify } from "react-notify-toast";
import {
  formatCurrency,
  getFormattedValuesForForm,
  removeEmptyFromObj,
} from "../../../lib";
import { EduRewardTypes } from "../EduConsts";
import { TripTypes } from "../../TripEvents/TripEventConsts";

import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

export default class RewardDetailsPage extends React.PureComponent {
  state = {
    authorized: true,
    viewOnly: false,

    eduProgram: null,
    reward: null,
    initialReward: null,

    errorMessage: "",
    loading: true,

    isRequestedPageLeave: false,
    showCancelChangesConfirmationModal: false,
    showSubmitConfirmationModal: false,
    submitAttempted: false,
    submitRewardErrorMessage: "",
    submitRewardLoading: false,

    systemLists: {},
    systemListsErrorMessage: "",
  };

  apiSignal = axios.CancelToken.source();
  incompleteSubmissionErrorMessage = "Please complete required fields";

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.EduFullView)) {
      this.setState({ authorized: false });
    } else if (!AuthService.UserHasClaim(PermissionClaims.EduFullEdit)) {
      this.setState({ viewOnly: true });
    }

    const { route, router } = this.props;
    router.setRouteLeaveHook(route, this.onLeave);

    this.getRewardDetails();

    this.getSystemLists();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { isRequestedPageLeave, initialReward, reward } = this.state;

    if (!isRequestedPageLeave && !_isEqual(reward, initialReward)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getRewardDetails = async () => {
    const {
      params: { rewardId, scheduleId },
    } = this.props;

    this.setState({
      loading: true,
    });

    try {
      const eduProgram = await EduApi.getEduProgram(
        this.apiSignal.token,
        scheduleId
      );

      let reward;
      if (rewardId) {
        const rewardDetails = await EduApi.getEduProgramReward(
          this.apiSignal.token,
          eduProgram.id,
          rewardId
        );
        reward = getFormattedValuesForForm(rewardDetails);
      } else {
        reward = {
          creditsRestrictedFromDate: "",
          creditValueCAD: "",
          creditValueGBP: "",
          creditValueUSD: "",
          isAvailable: true,
          isPartialRedemptionAllowed: true,
          longDescription: "",
          maxCredits: "",
          name: "",
          notes: "",
          shortDescription: "",
          tripEventID: "",
          tripType: "",
          type: "",
        };
      }

      this.setState({
        eduProgram,
        initialReward: _cloneDeep(reward),
        loading: false,
        reward,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and reward details could not be retrieved. Please try again."
          ),
          loading: false,
        });
      }
    }
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists([
        "eduRewardTypes",
        "tripTypes",
      ]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          systemListsErrorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  onChangeReward = (name, value, otherUpdates) => {
    let reward = _cloneDeep(this.state.reward);
    _set(reward, name, value);

    if (otherUpdates) {
      Object.keys(otherUpdates).forEach((update) =>
        _set(reward, update, otherUpdates[update])
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ reward }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { submitRewardErrorMessage } = this.state;
        if (
          submitRewardErrorMessage === this.incompleteSubmissionErrorMessage
        ) {
          const isValid = this.validateReward();
          if (isValid) {
            this.setState({
              submitRewardErrorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeRewardEvt = ({ target: { name, value } }) => {
    return this.onChangeReward(name, value);
  };

  onChangeRewardType = (type, eduRewardTypes) => {
    const rewardType = eduRewardTypes.find((r) => r.enumValue === type.value);

    // reset reward fields
    const rewardUpdates = {
      creditsRestrictedFromDate: "",
      creditValueUSD: rewardType?.value || "",
      creditValueGBP: "",
      creditValueCAD: "",
      maxCredits: "",
      isPartialRedemptionAllowed: true,
      tripType: "",
    };

    this.onChangeReward("type", type.value, rewardUpdates);
  };

  onCancelRewardChanges = () => {
    const { initialReward, reward } = this.state;
    if (_isEqual(reward, initialReward)) {
      browserHistory.push(`/edu/${this.props.params.scheduleId}?tab=rewards`);
      return;
    }

    this.setState({ showCancelChangesConfirmationModal: true });
  };

  cancelRewardChanges = () => {
    this.setState(
      {
        isRequestedPageLeave: true,
        reward: _cloneDeep(this.state.initialReward),
        showCancelChangesConfirmationModal: false,
        submitAttempted: false,
        submitRewardErrorMessage: "",
      },
      () =>
        browserHistory.push(`/edu/${this.props.params.scheduleId}?tab=rewards`)
    );
  };

  onSubmitReward = () => {
    this.setState({
      submitAttempted: true,
      submitRewardErrorMessage: "",
    });

    const isValid = this.validateReward();
    if (!isValid) {
      return;
    }

    this.setState({
      showSubmitConfirmationModal: true,
    });
  };

  submitReward = async () => {
    this.setState({
      showSubmitConfirmationModal: false,
      submitRewardErrorMessage: "",
      submitRewardLoading: true,
    });

    try {
      const rewardForSubmission = _cloneDeep(this.state.reward);
      removeEmptyFromObj(rewardForSubmission);
      await EduApi.submitEduProgramReward(
        this.apiSignal.token,
        this.state.eduProgram.id,
        rewardForSubmission
      );

      this.setState(
        {
          isRequestedPageLeave: true,
        },
        () => {
          browserHistory.push(
            `/edu/${this.props.params.scheduleId}?tab=rewards`
          );
          notify.show("Your reward has been saved", "success");
        }
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          submitRewardErrorMessage: ApiCallErrorMessageHandler(err),
          submitRewardLoading: false,
        });
      }
    }
  };

  validateReward = () => {
    const {
      reward: {
        creditValueUSD,
        creditValueGBP,
        creditValueCAD,
        name,
        tripType,
        type,
      },
    } = this.state;

    const incompleteCreditValueInfo =
      type !== EduRewardTypes.Trip &&
      (tripType
        ? !creditValueUSD
        : !creditValueUSD || !creditValueGBP || !creditValueCAD);

    if (!name || incompleteCreditValueInfo) {
      this.setState({
        submitRewardErrorMessage: this.incompleteSubmissionErrorMessage,
      });
      return false;
    }

    return true;
  };

  render() {
    const {
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      errorMessage,
      initialReward,
      loading,
      reward,
      showCancelChangesConfirmationModal,
      showSubmitConfirmationModal,
      submitAttempted,
      submitRewardErrorMessage,
      submitRewardLoading,
      systemLists: { eduRewardTypes, tripTypes },
      systemListsErrorMessage,
      viewOnly,
    } = this.state;

    const {
      creditsRestrictedFromDate,
      creditValueCAD,
      creditValueGBP,
      creditValueUSD,
      id: rewardId,
      isAvailable,
      isPartialRedemptionAllowed,
      maxCredits,
      name,
      notes,
      shortDescription,
      tripEvent,
      tripType,
      type,
    } = reward || {};
    const {
      hasTripEnded,
      isTripPublished,
      tripProgramScheduleID,
      tripProgramScheduleName,
    } = tripEvent || {};

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="edit-reward-page page container">
        <ProgramBreadcrumbsHeader
          getPrevPages={(sched) => [
            {
              path: `/edu/${sched.scheduleID}?tab=rewards`,
              title: `Manage ${sched.scheduleName || sched.programName}`,
            },
          ]}
          pageTitle="Rewards"
          scheduleId={scheduleId}
        />
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !reward ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          <div className="card full-width">
            <div className="flex flex-align-center flex-justify-space mb-16 reward-form-header">
              <p className="flex flex-align-center xxl-text">
                <i
                  className="material-icons link-text mr-16"
                  onClick={() => browserHistory.goBack()}
                >
                  arrow_back
                </i>{" "}
                {rewardId ? "Edit" : "Create New"} Reward
              </p>
              <span className="tooltip-container">
                <Toggle
                  className="active-toggle"
                  disabled={
                    viewOnly ||
                    (isAvailable && isTripPublished && !hasTripEnded) // don't allow 'deactivating' a reward linked to a published trip before trip end
                  }
                  name="isAvailable"
                  options={[
                    {
                      value: true,
                      display: "Active",
                    },
                    { value: false, display: "Inactive" },
                  ]}
                  onChange={this.onChangeReward}
                  value={isAvailable}
                />
                {isAvailable && isTripPublished && !hasTripEnded && (
                  <span className="tooltip tooltip-medium-width">
                    Reward cannot be deactivated because it is linked to a
                    published trip event
                  </span>
                )}
              </span>
            </div>
            <div className="reward-form">
              {type === EduRewardTypes.Trip && (
                <div className="flex flex-align-center mb-16">
                  <label className="accent-text-dark">
                    Trip Event Settings
                  </label>
                  <Link
                    className="link-text"
                    to={`/programs/trips/${tripProgramScheduleID}`}
                  >
                    {tripProgramScheduleName}
                  </Link>
                  {isAvailable && !isTripPublished && (
                    <span className="tooltip-container ml-16">
                      <i className="material-icons large-text link-text no-hover">
                        info
                      </i>
                      <span className="tooltip tooltip-medium-width">
                        Note that details of this unpublished trip will be
                        public while reward is active.
                      </span>
                    </span>
                  )}
                </div>
              )}
              <div className="flex flex-align-center mb-16">
                <label className="accent-text-dark">Reward Name</label>
                {type === EduRewardTypes.Trip ? ( // cannot update name for a Trip reward
                  <div>{name}</div>
                ) : (
                  <input
                    className={`custom-input${
                      submitAttempted && !name ? " error" : ""
                    }`}
                    disabled={viewOnly}
                    name="name"
                    onChange={this.onChangeRewardEvt}
                    style={{ minWidth: "200px" }}
                    type="text"
                    value={name}
                  />
                )}
              </div>
              <div className="flex flex-align-center mb-16">
                <label className="accent-text-dark">Reward Type</label>
                {type === EduRewardTypes.Trip ? ( // cannot update type for a Trip reward
                  <div>
                    {
                      eduRewardTypes?.find((t) => t.enumValue === type)
                        ?.displayValue
                    }
                  </div>
                ) : (
                  <Select
                    clearable={false}
                    disabled={viewOnly}
                    onChange={(t) => this.onChangeRewardType(t, eduRewardTypes)}
                    options={
                      eduRewardTypes &&
                      eduRewardTypes
                        .filter(
                          (rt) =>
                            type === EduRewardTypes.Trip ||
                            rt.enumValue !== EduRewardTypes.Trip // cannot create a new Trip reward
                        )
                        .map((rewardType) => ({
                          label: rewardType.displayValue,
                          value: rewardType.enumValue,
                        }))
                    }
                    style={{
                      height: "32px",
                      minWidth: "200px",
                      border: "1px solid #edecec",
                    }}
                    value={type}
                  />
                )}
              </div>
              {systemListsErrorMessage && (
                <FadeOutErrorMessage message="Something went wrong and we could not retrieve reward types.  Please try again." />
              )}
              {type === EduRewardTypes.Programs && (
                <div className="flex flex-align-center mb-16">
                  <label className="accent-text-dark flex flex-align-center">
                    Trip Type
                    <div className="tooltip-container">
                      <i className="material-icons accent-text-dark large-text ml-8">
                        info
                      </i>
                      <span className="tooltip">
                        Reward with a Trip Type is redeemable from within
                        applications for trips of the selected type.
                        <br />
                        It is not redeemable as a standalone reward.
                      </span>
                    </div>
                  </label>
                  <Select
                    disabled={viewOnly}
                    onChange={(type) =>
                      this.onChangeReward(
                        "tripType",
                        type?.value || "",
                        // when a tripType is selected: CAD and GBP credit values are irrelevant, creditsRestrictedFromDate is not allowed
                        creditValueCAD ||
                          creditValueGBP ||
                          creditsRestrictedFromDate
                          ? {
                              creditValueCAD: "",
                              creditValueGBP: "",
                              creditsRestrictedFromDate: "",
                            }
                          : null
                      )
                    }
                    options={
                      tripTypes &&
                      tripTypes
                        .filter((type) => type.enumValue !== TripTypes.JewishU)
                        .map((type) => ({
                          label: type.displayValue,
                          value: type.enumValue,
                        }))
                    }
                    style={{
                      height: "32px",
                      minWidth: "200px",
                      border: "1px solid #edecec",
                    }}
                    value={tripType}
                  />
                </div>
              )}
              <div className="flex flex-align-center mb-16">
                <label className="accent-text-dark flex flex-align-center">
                  Credit Value
                  {type === EduRewardTypes.Trip && (
                    <span className="tooltip-container">
                      <i className="material-icons large-text ml-8">info</i>
                      <span className="tooltip">
                        Credit value can be updated from the Trip Event Settings
                        page for the linked trip
                      </span>
                    </span>
                  )}
                </label>
                {type === EduRewardTypes.Trip ? ( // cannot update value for a Trip reward
                  <div>${formatCurrency(creditValueUSD)}</div>
                ) : (
                  <div className="reward-currency-values">
                    <span>
                      USD
                      <NumberFormat
                        allowNegative={false}
                        className={`custom-input dollar-input ml-8 mr-20${
                          submitAttempted && !creditValueUSD ? " error" : ""
                        }`}
                        decimalScale={0}
                        disabled={viewOnly}
                        onValueChange={({ floatValue }) =>
                          this.onChangeReward(
                            "creditValueUSD",
                            floatValue >= 0 ? floatValue : ""
                          )
                        }
                        thousandSeparator={true}
                        value={creditValueUSD}
                      />
                    </span>
                    {!tripType && (
                      <>
                        <span>
                          GBP
                          <NumberFormat
                            allowNegative={false}
                            className={`custom-input pound-input ml-8 mr-20${
                              submitAttempted && !creditValueGBP ? " error" : ""
                            }`}
                            decimalScale={0}
                            disabled={viewOnly}
                            onValueChange={({ floatValue }) =>
                              this.onChangeReward(
                                "creditValueGBP",
                                floatValue >= 0 ? floatValue : ""
                              )
                            }
                            thousandSeparator={true}
                            value={creditValueGBP}
                          />
                        </span>
                        <span>
                          CAD
                          <NumberFormat
                            allowNegative={false}
                            className={`custom-input dollar-input ml-8 mr-20${
                              submitAttempted && !creditValueCAD ? " error" : ""
                            }`}
                            decimalScale={0}
                            disabled={viewOnly}
                            onValueChange={({ floatValue }) =>
                              this.onChangeReward(
                                "creditValueCAD",
                                floatValue >= 0 ? floatValue : ""
                              )
                            }
                            thousandSeparator={true}
                            value={creditValueCAD}
                          />
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
              {type !== EduRewardTypes.Trip && (
                <>
                  <div className="flex flex-align-center mb-16">
                    <label className="accent-text-dark">Max Credits</label>
                    <NumberFormat
                      allowNegative={false}
                      className="custom-input"
                      decimalScale={0}
                      disabled={viewOnly}
                      onValueChange={({ floatValue }) =>
                        this.onChangeReward(
                          "maxCredits",
                          floatValue >= 0 ? floatValue : "",
                          // partial redemption must be allowed if there is no max credits value
                          !floatValue && !isPartialRedemptionAllowed
                            ? { isPartialRedemptionAllowed: true }
                            : null
                        )
                      }
                      style={{ width: "80px" }}
                      thousandSeparator={true}
                      value={maxCredits}
                    />
                    {maxCredits > 0 && creditValueUSD > 0 && (
                      <div className="ml-16 accent-text">
                        ${formatCurrency(maxCredits * creditValueUSD)} max
                        reward value
                      </div>
                    )}
                  </div>
                  <div className="flex flex-align-center mb-16">
                    <label className="accent-text-dark">
                      Allow Partial Credit Redemption
                    </label>
                    <Toggle
                      disabled={viewOnly || !maxCredits}
                      name="isPartialRedemptionAllowed"
                      onChange={this.onChangeReward}
                      options={[
                        { value: false, display: "Only Full" },
                        { value: true, display: "Partial" },
                      ]}
                      value={isPartialRedemptionAllowed}
                    />
                  </div>
                </>
              )}
              {/* Credit Restrictions are not allowed for Program rewards with a tripType */}
              {!(type === EduRewardTypes.Programs && tripType) && (
                <div className="mb-24">
                  <div className="flex flex-align-center mb-8">
                    <label className="medium-text accent-text-dark block mt-4 mb-4">
                      Credit Restrictions
                    </label>
                    <div className="tooltip-container">
                      <i className="material-icons accent-text-dark large-text ml-8">
                        info
                      </i>
                      <span className="tooltip">
                        Require JewishU credits to be earned within a limited
                        timeframe
                      </span>
                    </div>
                  </div>
                  <label className="accent-text-dark">
                    Credits must be earned starting from
                  </label>
                  <DatePicker
                    className="custom-input ml-8 mr-8"
                    onChange={(date) =>
                      this.onChangeReward("creditsRestrictedFromDate", date)
                    }
                    selected={
                      creditsRestrictedFromDate
                        ? moment(creditsRestrictedFromDate)
                        : null
                    }
                  />
                </div>
              )}
              <div className="mb-24">
                <div className="flex flex-align-center mb-8">
                  <label className="accent-text-dark">
                    Short Reward Description
                  </label>
                  <div className="tooltip-container">
                    <i className="material-icons accent-text-dark large-text ml-8">
                      info
                    </i>
                    <span className="tooltip">
                      This will be displayed in the public site reward details.
                    </span>
                  </div>
                </div>
                <textarea
                  className="custom-input full-width"
                  disabled={viewOnly}
                  name="shortDescription"
                  onChange={this.onChangeRewardEvt}
                  value={shortDescription}
                />
              </div>
              <div className="mb-24">
                <div className="flex flex-align-center mb-8">
                  <label className="accent-text-dark">Notes</label>
                  <div className="tooltip-container">
                    <i className="material-icons accent-text-dark large-text ml-8">
                      info
                    </i>
                    <span className="tooltip">For internal admin notes</span>
                  </div>
                </div>
                <textarea
                  className="custom-input full-width"
                  disabled={viewOnly}
                  name="notes"
                  onChange={this.onChangeRewardEvt}
                  value={notes}
                />
              </div>
            </div>
            {!viewOnly && (
              <div className="reward-form-btns flex flex-justify-space mt-16 relative">
                <button
                  className="custom-btn btn-light uppercase-text"
                  disabled={submitRewardLoading}
                  onClick={this.onCancelRewardChanges}
                >
                  Cancel
                </button>
                {submitRewardLoading ? (
                  <div className="medium-loader">
                    <Loader />
                  </div>
                ) : (
                  <React.Fragment>
                    <button
                      className="custom-btn btn-accent uppercase-text"
                      disabled={_isEqual(reward, initialReward)}
                      onClick={this.onSubmitReward}
                    >
                      Save
                    </button>
                    {!!submitRewardErrorMessage && (
                      <FadeOutErrorMessage
                        className="error-text text-right block"
                        message={submitRewardErrorMessage}
                        onTimeout={() =>
                          this.setState({
                            submitRewardErrorMessage: "",
                          })
                        }
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
            )}
            <ConfirmationModal
              cancel={() =>
                this.setState({
                  showCancelChangesConfirmationModal: false,
                  showSubmitConfirmationModal: false,
                })
              }
              cancelText={showCancelChangesConfirmationModal && "No"}
              confirm={
                showCancelChangesConfirmationModal
                  ? this.cancelRewardChanges
                  : this.submitReward
              }
              confirmText={showCancelChangesConfirmationModal && "Yes"}
              message={`Are you sure you want to ${
                showCancelChangesConfirmationModal
                  ? "cancel your changes for this reward?"
                  : `save ${rewardId ? "changes to " : ""}this reward?`
              }`}
              show={
                showCancelChangesConfirmationModal ||
                showSubmitConfirmationModal
              }
            />
          </div>
        )}
      </div>
    );
  }
}

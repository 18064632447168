import React from "react";

import CustomSelect from "../../Form/CustomSelect";
import FormInput from "../../Form/FormInput";
import FormInputHebrew from "../../Form/FormInputHebrew";
import FormInputUpdate from "../../Form/FormInputUpdate";
import ImpersonateUser from "../../../../shared/ImpersonateUser";
import RadioContainer from "../../Form/RadioContainer";
import AuthService, {
  PermissionClaims,
} from "../../../../../services/AuthService";
import { impersonationUserTypes, isNotValidDOB } from "../../../../../lib";
import HebrewBirthdayField from "./PersonHebrewBirthdayField";

export default class SpouseInfo extends React.PureComponent {
  state = {
    spouseInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        spouseInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      require,
      setFieldValue,
      touched,
      values,
      values: { family: { address: { country } = {} } = {} },
      sys: { countries, titles },
      hasUSAddress,
      readOnly,
      shliachType,
    } = this.props;

    const profileImageURL = values?.spouse?.profileImageURL;
    const personalEmail = values?.spouse?.personalEmail;

    return (
      <div
        className={
          this.state.spouseInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <div className="flex flex-align-center mt-48 mb-16 mobile-flex-justify-space">
          <p
            className="accent-text-dark medium-text fw-700"
            onClick={() => {
              this.setState({
                spouseInfoShowing: !this.state.spouseInfoShowing,
              });
            }}
          >
            {shliachType} Information
          </p>
          {AuthService.UserHasClaim(PermissionClaims.UserImpersonation) &&
            personalEmail && (
              <ImpersonateUser
                className="ml-16"
                username={personalEmail}
                userType={impersonationUserTypes.Shliach}
              />
            )}
          {!values.spouse && (
            <div className="tooltip-container">
              <i
                className={`material-icons link-text no-hover profile-add-icon ml-16 ${
                  !this.state.spouseInfoShowing ? "hidden" : ""
                }`}
              >
                info
              </i>
              <span className="tooltip">
                There is no spouse displayed because no spouse was set when
                shliach was created. Contact tech admin to add a spouse
              </span>
            </div>
          )}
        </div>

        <div className="mb-16">
          <label className="line-height-double accent-text small-text">
            Profile Image
          </label>
          {profileImageURL ? (
            <img
              alt="spouse-profile"
              src={profileImageURL}
              style={{
                height: "60px",
                width: "60px",
                objectFit: "cover",
                borderRadius: "100%",
              }}
            />
          ) : (
            "Image not uploaded"
          )}
        </div>

        <div
          className={`profile-form-inputs ${
            !values.spouse ? "spouse-disabled" : ""
          }`}
        >
          <div className="spouse-card">
            <div className="flex flex-justify-space flex-align-center mr-24">
              <FormInput
                label="First Name"
                type="text"
                name="spouse.firstName"
                validate={!values.spouse ? "" : require}
                className={!values.spouse ? "" : "form-required"}
                errors={errors}
                touched={touched}
                disabled={!values.spouse ? true : false}
                readOnly={readOnly}
              />
            </div>
            <div className="flex flex-justify-space flex-align-center">
              <FormInput
                label="Last Name"
                type="text"
                name="spouse.lastName"
                validate={!values.spouse ? "" : require}
                className={!values.spouse ? "" : "form-required"}
                errors={errors}
                touched={touched}
                disabled={!values.spouse ? true : false}
                readOnly={readOnly}
              />
            </div>
            <div className="flex flex-justify-space flex-align-center mr-24">
              <FormInput
                label="Name called by"
                type="text"
                name="spouse.nickname"
                errors={errors}
                touched={touched}
                disabled={!values.spouse ? true : false}
                readOnly={readOnly}
              />
            </div>
            <div className="flex flex-justify-space flex-align-center relative">
              <FormInputHebrew
                label="Hebrew Name"
                type="text"
                name="spouse.hebrewName"
                placeholder="ex: Chana bas Rivkah"
                errors={errors}
                touched={touched}
                disabled={!values.spouse ? true : false}
                readOnly={readOnly}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="flex flex-justify-space flex-align-center mr-24">
              <FormInputUpdate
                label="Title"
                name="spouse.title"
                errors={errors}
                touched={touched}
              >
                {({ field }) => (
                  <CustomSelect
                    {...field}
                    errors={errors}
                    disabled={!values.spouse ? true : false}
                    readOnly={readOnly}
                    options={
                      titles &&
                      titles.map((t) => ({
                        key: t.enumValue,
                        value: t.enumValue,
                        label: t.displayValue,
                      }))
                    }
                    placeholder="Select Title..."
                    setFieldValue={setFieldValue}
                    touched={touched}
                  />
                )}
              </FormInputUpdate>
            </div>
            <div className="flex flex-align-center">
              <FormInput
                label="Gender"
                name="spouse.gender"
                validate={!values.spouse ? "" : require}
                className={!values.spouse ? "" : "form-required"}
                errors={errors}
                touched={touched}
              >
                {({ field }) => (
                  <RadioContainer
                    errors={errors}
                    touched={touched}
                    disabled={!values.spouse ? true : false}
                    readOnly={readOnly}
                    {...field}
                    input1={{
                      id: "male-spouse",
                      value: "Male",
                      checked: "Male",
                      label: "Male",
                    }}
                    input2={{
                      id: "female-spouse",
                      value: "Female",
                      checked: "Female",
                      label: "Female",
                    }}
                  />
                )}
              </FormInput>
            </div>
            <div className="flex flex-justify-space flex-align-center mr-24 relative">
              <FormInput
                label="Birthday"
                type="date"
                name="spouse.dob"
                validate={(date) => isNotValidDOB(date)}
                errors={errors}
                touched={touched}
                disabled={!values.spouse ? true : false}
                readOnly={readOnly}
              />
            </div>
            <div className="flex flex-align-center">
              <FormInput
                label="Nightfall/Sunset"
                name="spouse.beforeNightfall"
                errors={errors}
                touched={touched}
              >
                {({ field }) => (
                  <RadioContainer
                    errors={errors}
                    touched={touched}
                    disabled={!values.spouse ? true : false}
                    readOnly={readOnly}
                    {...field}
                    input1={{
                      id: "before-spouse",
                      value: true,
                      checked: "true",
                      label: "Before",
                    }}
                    input2={{
                      id: "after-spouse",
                      value: false,
                      checked: "false",
                      label: "After",
                    }}
                  />
                )}
              </FormInput>
            </div>

            <HebrewBirthdayField hebrewDOB={values.spouse?.hebrewDOB} />
          </div>

          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Date Joined COCI"
              type="date"
              name="dateJoinedCOCI"
              validate={require}
              className="form-required"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>

          <div className="flex" style={{ gridColumnStart: "span 2" }}>
            <label className="accent-text small-text mt-8 form-required">
              Home Address
            </label>
            <div style={{ width: "100%" }}>
              <div className="relative">
                <FormInput
                  type="text"
                  name="family.address.address1"
                  validate={require}
                  errors={errors}
                  touched={touched}
                  className="mb-8"
                  readOnly={readOnly}
                />
              </div>
              <div className="relative">
                <FormInput
                  type="text"
                  name="family.address.address2"
                  errors={errors}
                  touched={touched}
                  readOnly={readOnly}
                  className="mb-8"
                  placeholder="Apt #, Suite, Floor"
                />
              </div>
              <div className="address-grid">
                <div className="relative">
                  <FormInputUpdate
                    name="family.address.country"
                    errors={errors}
                    validate={require}
                    touched={touched}
                    readOnly={readOnly}
                    className="mr-8"
                  >
                    {({ field }) => (
                      <CustomSelect
                        {...field}
                        errors={errors}
                        readOnly={readOnly}
                        options={
                          countries &&
                          countries.map((c) => ({
                            key: c.name,
                            value: c.name,
                            label: c.name,
                          }))
                        }
                        placeholder="Select Country..."
                        setFieldValue={setFieldValue}
                        touched={touched}
                      />
                    )}
                  </FormInputUpdate>
                </div>
                <div className="relative">
                  <FormInput
                    type="text"
                    name="family.address.city"
                    validate={require}
                    errors={errors}
                    touched={touched}
                    className="mr-8"
                    placeholder="City"
                    readOnly={readOnly}
                  />
                </div>
                <div className="relative">
                  <FormInputUpdate
                    name="family.address.state"
                    errors={errors}
                    touched={touched}
                    className="mr-8"
                  >
                    {({ field }) =>
                      country === "USA" ||
                      country === "Canada" ||
                      country === "Mexico" ? (
                        <CustomSelect
                          {...field}
                          errors={errors}
                          isClearable={true}
                          readOnly={readOnly}
                          options={(
                            (countries &&
                              countries
                                .filter((c) => c.name === country)
                                .map((c) => c.states)[0]) ||
                            []
                          ).map((s) => ({
                            key: s.code,
                            value: s.name,
                            label: s.name,
                          }))}
                          placeholder="Select State/Region..."
                          setFieldValue={setFieldValue}
                          touched={touched}
                        />
                      ) : (
                        <FormInputUpdate
                          name="family.address.state"
                          type="text"
                          placeholder="State/Region"
                          validate={
                            hasUSAddress(values.family) ? require : null
                          }
                          errors={errors}
                          readOnly={readOnly}
                          touched={touched}
                          className="mr-8"
                        />
                      )
                    }
                  </FormInputUpdate>
                </div>
                <div className="relative">
                  <FormInput
                    type="text"
                    name="family.address.zip"
                    errors={errors}
                    readOnly={readOnly}
                    touched={touched}
                    placeholder="Zip"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
